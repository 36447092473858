<template>
  <div id="home">
    <div class="head_banner">
      <el-carousel arrow="arrow" :interval="5000" :height="bannerHeight + 'px'">
        <el-carousel-item v-for="item in imgArr" :key="item">
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="mobile_banner">
      <el-carousel arrow="arrow" :interval="5000">
        <el-carousel-item v-for="item in imgArrPhone" :key="item">
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="akawyer">
      <div class="akawyers">
        <div class="akawyers_tit">
          <p class="zh">什么情况下需要找涉外律师</p>
          <p class="en">LOOKING FOR A LAWYER</p>
          <div class="dian"></div>
        </div>
        <div class="akawyers_msg">
          <div
            v-for="(x, i) in topQuestionList"
            :key="i"
            :class="'msg' + (i + 1)"
          >
            <div class="model">
              <div>
                {{ x.answer }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="problem">
      <div class="problems">
        <div class="problems_tit">
          <p class="zh">涉外案件（项目）常见问题</p>
          <p class="en">COMMON PROBLEM</p>
          <div class="dian"></div>
        </div>
        <div class="problems_msg">
          <ul>
            <li
              class="first"
              v-for="(x, i) in questionList"
              :key="i"
              @click="goDetails(x.id)"
            >
              <img src="@/assets/h5/lanse.png" alt="" />
              <div class="left" v-if="i % 2 != 0">
                <p>{{ x.question }}</p>
                <div>
                  {{ x.answer }}
                </div>
              </div>
              <div class="right" v-else>
                <p>{{ x.question }}</p>
                <div>
                  {{ x.answer }}
                </div>
              </div>
            </li>
          </ul>
          <div class="problemsbg">
            <div>
              盈科上海<br />
              涉外服务
            </div>
            <p>400-662-2729</p>
            <div class="ask" @click="callPhone('400-662-2729')">立即咨询</div>
          </div>
        </div>
        <!-- <div class="understand">了解更多>></div> -->
      </div>
    </div>
    <div class="service">
      <div class="services">
        <div class="services_tit">
          <p class="zh">我们的服务</p>
          <p class="en">OUR SERVICES</p>
          <div class="dian"></div>
        </div>
        <div class="services_msg">
          <ul>
            <li
              v-for="(x, i) in serviceList"
              :key="i"
              @click="toServiceDetail(x.id)"
            >
              <div class="imgs"><img :src="x.logo" alt="" /></div>
              <div class="texts">
                <p>{{ x.name }}</p>
                <div>
                  {{ x.content }}
                </div>
                <span>了解更多>></span>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="team">
      <div class="teams">
        <div class="teams_tit">
          <p class="zh">涉外精英律师团队</p>
          <p class="en">OUR TEAM</p>
          <div class="dian"></div>
        </div>
        <div class="rotation">
          <div class="swiper">
            <div class="left">
              <div class="swiperTop">
                <div class="swiper_tit">
                  <span>{{ lawyerList[currentIndex].name }}</span
                  >&nbsp; <span>{{ lawyerList[currentIndex].englishName }}</span
                  >&nbsp;&nbsp;&nbsp;
                  <span>{{ lawyerList[currentIndex].type }}</span>
                </div>
                <div class="swiper_msg">
                  <p class="adress">
                    <span
                      >{{
                        lawyerList[currentIndex].officeName
                      }}&nbsp;|&nbsp;</span
                    >
                    <span
                      v-for="(job, l) in lawyerList[currentIndex].jobList"
                      :key="l"
                      >{{ job }}</span
                    >
                  </p>
                  <p class="lingyu">业务领域</p>
                  <p class="lingyumsg">
                    {{ lawyerList[currentIndex].businessAreas }}
                  </p>
                </div>
                <div
                  class="know"
                  @click="toLawyerDetail(lawyerList[currentIndex].id)"
                >
                  了解更多>>
                </div>
              </div>
              <div class="imgs">
                <li
                  v-for="(item, index) in lawyerList"
                  @click="clickTba(index)"
                  :class="{ active: currentIndex == index }"
                  :key="item"
                >
                  <img
                    style="border: 5px solid rgba(200, 200, 200, 0)"
                    :src="item.photo"
                    alt=""
                  />
                </li>
              </div>
            </div>
            <div class="right">
              <li>
                <img :src="lawyerList[currentIndex].largerPhoto" />
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="contact" id="contentUs">
      <div class="contacts">
        <div class="contacts_tit">
          <p class="zh">联系我们</p>
          <p class="en">CONTACT US</p>
          <div class="dian"></div>
        </div>
        <div class="Item-from">
          <el-form ref="form_bottom">
            <el-row class="Item-flex">
              <el-col class="left">
                <el-form-item>
                  <div class="user">您的姓名</div>
                  <el-input
                    placeholder="请输入您的姓名"
                    v-model="form.username"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="user">您的邮箱</div>
                  <el-input
                    placeholder="请输入您的邮箱"
                    v-model="form.email"
                  ></el-input>
                </el-form-item>
                <el-form-item id="yourtel">
                  <div class="user">您的手机号码</div>
                  <el-input
                    placeholder="请输入您的手机号码"
                    class="userPhone"
                    v-model="form.phone"
                  >
                  </el-input>
                  <el-button class="sendCode" @click="GetCode(form.phone)">{{
                    isSendCode
                  }}</el-button>
                </el-form-item>
                <el-form-item>
                  <div class="user">输入验证码</div>
                  <el-input
                    placeholder="您收到的验证码"
                    v-model="form.code"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <div class="user">您的公司名称</div>
                  <el-input
                    placeholder="请输入您的公司名称"
                    v-model="form.company"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col class="right">
                <el-form-item class="select">
                  <el-select
                    v-model="provinceId"
                    placeholder="请选择省份"
                    @change="selectProvince"
                  >
                    <el-option
                      v-for="item in provinceList"
                      :key="item.regionId"
                      :label="item.regionName"
                      :value="item.regionId"
                    ></el-option>
                  </el-select>
                  <el-select
                    v-model="cityId"
                    placeholder="请选择市区"
                    @change="selectCity"
                  >
                    <el-option
                      v-for="item in cityList"
                      :key="item.regionId"
                      :label="item.regionName"
                      :value="item.regionId"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="leibie" id="leibie">
                  <el-select placeholder="咨询类别" v-model="form.advisoryType">
                    <el-option label="民事" value="38"></el-option>
                    <el-option label="刑事" value="39"></el-option>
                    <el-option label="行政" value="40"></el-option>
                    <el-option label="其他" value="41"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="question">
                  <el-input
                    placeholder="请再次输入您的问题描述"
                    v-model="form.problemDes"
                  ></el-input>
                </el-form-item>
                <el-form-item class="submit">
                  <el-button @click="onSubmit" :disabled="disabled"
                    >提 交</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div class="contacts_bottom" id="adress">
          <div class="left">
            <div class="map1">
              <baidu-map
                class="map"
                :center="{ lng: 121.459867, lat: 31.250989 }"
                :zoom="19"
                :scroll-wheel-zoom="true"
              >
                <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale>
                <bm-marker
                  :position="{ lng: 121.459867, lat: 31.250989 }"
                  :dragging="true"
                  animation="BMAP_ANIMATION_BOUNCE"
                >
                </bm-marker>
              </baidu-map>
            </div>
            <div class="map2">
              <baidu-map
                class="map"
                :center="{ lng: 121.444609, lat: 31.299962 }"
                :zoom="19"
                :scroll-wheel-zoom="true"
              >
                <bm-scale anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-scale>
                <bm-marker
                  :position="{ lng: 121.444609, lat: 31.299962 }"
                  :dragging="true"
                  animation="BMAP_ANIMATION_BOUNCE"
                >
                </bm-marker>
              </baidu-map>
            </div>
          </div>
          <div class="right">
            <ul>
              <li>
                <img src="@/assets/dizhi1.png" alt="" />
                <div>联系电话：{{phone}}</div>
              </li>
              <li>
                <img src="@/assets/dianhua1.png" alt="" />
                <div>
                  <p>
                    联系地址<span class="one">1</span
                    >：{{address}}
                  </p>
                  <!-- <p class="adress2">
                    联系地址2：上海市静安区江场三路181号盈科律师大厦
                  </p> -->
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="zixun_tel">
      <div class="zixun_top">
        <img src="@/assets/zixun.png" alt="" />在线咨询
      </div>
      <div class="zixun_bottoom" @click="callPhone('400-662-2729')">
        <img src="@/assets/dianhua2.png" alt="" /> 400-662-2729
      </div>
      <div class="froms">
        <div class="kuaisu">- 快速咨询 -</div>
        <el-form ref="form_right">
          <el-row class="Item-flex">
            <el-form-item>
              <img src="@/assets/smalluse.png" alt="" />
              <el-input
                placeholder="请输入您的姓名"
                v-model="form.username"
              ></el-input>
            </el-form-item>
            <el-form-item id="yourtel">
              <img src="@/assets/smalltel.png" alt="" />
              <el-input
                placeholder="请输入您的手机号码"
                class="userPhone"
                v-model="form.phone"
              >
              </el-input>
            </el-form-item>
            <el-form-item>
              <img src="@/assets/yanzheng.png" alt="" />
              <el-input placeholder="输入验证码" v-model="form.code"></el-input>
              <el-button class="sendCode" @click="GetCode(form.phone)">{{
                isSendCode
              }}</el-button>
            </el-form-item>
            <el-form-item class="question">
              <img src="@/assets/smallwen.png" alt="" />
              <el-input
                placeholder="请输入您的问题描述"
                v-model="form.problemDes"
              ></el-input>
            </el-form-item>
            <el-form-item class="submit rightSub">
              <el-button @click="rightSubmit" :disabled="disabled"
                >提交表单</el-button
              >
            </el-form-item>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="guding">
      <div @click="callPhone('400-662-2729')">
        <img src="@/assets/h5/tel.png" alt="" />
      </div>
      <a href="#adress"><img src="@/assets/h5/adress.png" alt="" /></a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerHeight:"",
      questionList: [], //涉外问题
      topQuestionList: [], //置顶的涉外问题
      serviceList: [], //服务行业
      lawyerList: [], //律师
      currentIndex: 0, //默认显示图片
      timer: null, //定时器
      provinceId: "",
      cityId: "",
      phone:"",//联系方式
      address:"",//联系地址
      provinceList: [],
      cityList: [],
      isSendCode: "发送验证码",

      //联系我们表单
      form: {
        username: "",
        phone: "",
        address: "",
        advisoryType: "",
        problemDes: "",
        code: "",
      },
      disabled: false,
      imgArr: [
        require("@/assets/problembg1.jpg"),
        require("@/assets/problembg2.jpg"),
        require("@/assets/problembg3.jpg"),
        require("@/assets/problembg4.jpg"),
      ],
      imgArrPhone:[
        require("@/assets/1.jpg"),
        require("@/assets/2.jpg"),
        require("@/assets/3.jpg"),
        require("@/assets/4.jpg"),
      ],
    };
  },
  watch: {
    $route() {
      //监听相同路由下参数变化
      let index = this.$route.hash.replace("#", "");
      setTimeout(function () {
        let htmlContent = document.getElementById(index || "home");
        htmlContent.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
  },
  created() {
    this.getQuestionList();
    this.getServiceList();
    this.getLawyerList();
    this.getRegions();
    this.$store.state.navChange = 1;
  },
  computed: {
    // 计算当前图片位置
    current() {
      // 如果当前index等等于数组length-1 就return0 否则就当前index加1
      if (this.currentIndex == this.lawyerList.length - 1) {
        return 0;
      } else {
        return this.currentIndex + 1;
      }
    },
  },
  mounted() {
    // 执行定时器
    this.autoPlay();

    //获取联系方式和联系地址
    this.getPhoneAndAddress();

    let index = this.$route.hash.replace("#", "");
    setTimeout(function () {
      let htmlContent = document.getElementById(index || "home");
      htmlContent.scrollIntoView({ behavior: "smooth" });
    }, 100);


     //添加滚动监听
    window.addEventListener("scroll", this.handleScroll);
    // 首次加载时,初始化高度
    this.screenWidth = window.innerWidth;
    this.bannerHeight = (720 / 1920) * this.screenWidth;
    // 窗口大小发生改变
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.bannerHeight = (720 / 1920) * this.screenWidth;
    };
  },
  methods: {
    //获取联系电话和联系地址
    getPhoneAndAddress(){
       this.axios({
        type: "get",
        url: this.Url.apiUrl + "SystemOption/GetSetting?name=all",
      }).then((res) => {
        console.log(res)
        if(res.data&&res.data.length>0){
          res.data.forEach(p=>{
            if(p.name=="phone")this.phone = p.value
            if(p.name=="address")this.address = p.value
          })
          console.log(this.phone,this.address)
        }
      });
    },
    //拨号
    callPhone(phoneNumber) {
      window.location.href = "tel://" + phoneNumber;
    },
    // 当点击后 当前索引等于点击的index
    clickTba(index) {
      this.currentIndex = index;
    },
    // 定时器函数
    autoPlay() {
      this.timer = setInterval(() => {
        this.clickTba(this.current);
      }, 3000);
    },
    //获得涉外问题
    getQuestionList() {
      this.axios
        .get(this.Url.apiUrl + "ForeignQuestion/GetHomeForeignQuestion")
        .then((res) => {
          let dataList = res.data;
          for (var i = 0; i < dataList.length; i++) {
            let item = dataList[i];
            if (item.isTop) {
              this.topQuestionList.push(item);
            } else {
              this.questionList.push(item);
            }
          }
        });
    },
    //获得服务行业
    getServiceList() {
      this.axios
        .get(this.Url.apiUrl + "ServiceScope/GetServiceScope")
        .then((res) => {
          this.serviceList = res.data;
        });
    },
    //获得律师
    getLawyerList() {
      this.axios({
        type: "get",
        // url: this.Url.apiUrl + "Lawyer/GetTopLawyer",
        url: this.Url.apiUrl + "Lawyer/GetLawyer",
        params: {
          n: 5,
        },
      }).then((res) => {
        this.lawyerList = res.data.slice(0, 4);
      });
    },
    //跳转服务行业详情
    toServiceDetail(id) {
      this.$router.push({
        path: "/serviceTrade#" + id,
        params: {
          id: id,
        },
      });
    },
    //跳转到律师详情
    toLawyerDetail(id) {
      this.$router.push({
        name: "ProfessionalTeam",
        params: {
          id: id,
        },
      }); 
    },
    //获得地区
    getRegions(pId) {
      this.axios({
        type: "post",
        url: this.Url.apiUrl + "SystemOption/GetRegion",
        params: {
          parentRegionId: pId,
        },
      }).then((res) => {
        if (pId > 0) {
          this.cityList = res.data;
        } else {
          this.provinceList = res.data;
        }
      });
    },
    //选择省份
    selectProvince() {
      this.cityId = "";
      this.form.address = "";
      this.getRegions(this.provinceId);
    },
    //选择市区
    selectCity() {
      let provinceAddress = this.provinceList.find(
        (val) => val.regionId == this.provinceId
      ).regionName;
      let cityAddress = this.cityList.find(
        (val) => val.regionId == this.cityId
      ).regionName;
      this.form.address = provinceAddress + cityAddress;
    },

    //跳转文章详情
    goDetails(id) {
      let that = this;
      console.log(id, "666");
      this.$store.state.DesceOrder = that.DesceOrder;
      this.$router.push({
        name: "Mechanism-detail",
        params: {
          id: id,
          order: that.OrderIconVal,
          articleType: "mechanism",
        },
      });
    },
    //获取验证码
    GetCode(phone) {
      if (this.isSendCode == "已发送") {
        return false;
      }

      this.guid = this.getGuid();
      if (this.form.phone == "") {
        alert("请填写手机号");
        return false;
      } else if (!/^1[3|4|5|7|8][0-9]{9}$/.test(phone)) {
        alert("请填写正确的手机号");
        return false;
      }
      this.isSendCode = "已发送";
      this.axios({
        type: "post",
        url: this.Url.apiUrl + "sms/code/" + this.guid + "",
        params: {
          phone: phone,
        },
      }).then(() => {});
    },
    //提交表单
    onSubmit() {
      if (this.form.username == "") {
        alert("请输入您的姓名");
        return false;
      }
      if (this.form.advisoryType == "") {
        alert("请选择咨询类别");
        return false;
      }
      if (this.form.phone == "") {
        alert("请填写您的手机号");
        return false;
      }
      if (this.form.code == "") {
        alert("请输入您收到的验证码");
        return false;
      }
      if (this.form.problemDes == "") {
        alert("请输入问题概述");
        return false;
      }
      if (this.form.address == "") {
        alert("请选择您的地理位置");
        return false;
      }
      //验证输入的验证码是否正确
      if (this.guid == undefined) {
        alert("发送失败,请重新输入您的信息");
        return false;
      }

      this.disabled = true;
      this.axios({
        type: "post",
        url: this.Url.apiUrl + "v/code/" + this.guid + "",
        params: {
          phone: this.form.phone,
          code: this.form.code,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          alert("验证码不正确,请重新输入");
          this.disabled = false;
          return false;
        } else {
          //提交表单
          this.axios({
            method: "post",
            // url: this.Url.apiUrl + "SystemOption/CaseAdvisory",
            url: this.Url.apiUrl + "mail/send",
            data: this.form,
            headers: {
              "Content-Type": "application/json",
            },
          }).then(() => {
            alert("提交成功");
            this.disabled = false;
          });
        }
      });
      this.$refs["form_bottom"].resetFields();
    },
    //右侧提交
    rightSubmit() {
      if (this.form.username == "") {
        alert("请输入您的姓名");
        return false;
      }
      if (this.form.phone == "") {
        alert("请填写您的手机号");
        return false;
      }
      if (this.form.code == "") {
        alert("请输您收到的验证码");
        return false;
      }
      if (this.form.problemDes == "") {
        alert("请输入问题概述");
        return false;
      }
      //验证输入的验证码是否正确
      if (this.guid == undefined) {
        alert("发送失败,请重新输入您的信息");
        return false;
      }

      this.disabled = true;
      this.axios({
        type: "post",
        url: this.Url.apiUrl + "v/code/" + this.guid + "",
        params: {
          phone: this.form.phone,
          code: this.form.code,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          alert("验证码不正确,请重新输入");
          this.disabled = false;
          return false;
        } else {
          //提交表单
          this.axios({
            method: "post",
            // url: this.Url.apiUrl + "SystemOption/CaseAdvisory",
            url: this.Url.apiUrl + "mail/send",
            data: this.form,
            headers: {
              "Content-Type": "application/json",
            },
          }).then(() => {
            alert("提交成功");
            this.disabled = false;
            this.form = {
              username: "",
              phone: "",
              address: "",
              advisoryType: "",
              problemDes: "",
              code: "",
            };
          });
        }
      });
    },
    //获取随机 guid
    getGuid() {
      function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      }
      return (
        S4() +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        S4() +
        S4()
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/page_home.scss";
</style>
<style>
.Item-from .Item-flex .left .el-form-item {
  margin: 0;
}

.Item-flex .left .el-form-item__content {
  width: 80%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  line-height: 70px;
  border-bottom: 1px dotted #d5d5d5;
}
.Item-flex .right .el-form-item__content {
  width: 80%;
  margin: 0 auto;
  line-height: 70px;
}
.Item-flex .left .el-form-item__content .el-input {
  width: 300px;
  border: none;
}
.Item-flex .el-form-item__content .el-input input {
  border: none;
}
.froms {
  width: 80%;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
.froms .kuaisu {
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding-top: 10px;
  margin-top: 20px;
}
.froms .el-input__inner {
  background: rgba(0, 0, 0, 0);
  color: #fff;
}
.froms .submit {
  width: 50%;
  margin: 15px auto;
  background: #ffa128;
  border-radius: 8px;
}
.froms .submit button {
  width: 100%;
  color: white;
  background: #ffa128;
  border: 1px solid transparent;
  border-radius: 8px;
}
.froms .el-form-item {
  margin-bottom: 10px;
}
.froms .el-form-item__content {
  display: flex;
  align-items: center;
}
.froms .el-form-item__content img {
  width: 20px;
  height: 20px;
  margin-left: 10px;
}
.froms .sendCode {
  width: 33%;
  padding: 5px;
  margin-right: 10px;
  text-align: start;
  color: #ffa128;
  font-size: 12px;
}
.head_banner .el-carousel__container img {
  width: 100%;
  height: inherit;
}
@media screen and (max-width: 700px) {
  .mobile_banner .el-carousel__container {
    height: 420px;
  }
  .head_banner .el-carousel__container img {
    width: 100%;
    height: 100%;
  }
  .Item-flex .el-form-item {
    height: auto;
  }

  .Item-flex .left .el-form-item__content {
    line-height: normal;
  }
  .Item-flex .right .el-form-item__content {
    width: 100%;
  }
  .Item-from .Item-flex .left .el-form-item,
  .Item-from .Item-flex .right .el-form-item {
    margin-top: 10px;
  }

  .Item-from .Item-flex .left .el-form-item .el-input input,
  .Item-from .Item-flex .right .el-form-item .el-input input {
    padding: 0;
  }
  .Item-from .Item-flex .left .el-form-item .el-form-item__content {
    flex-direction: column;
    align-items: flex-start;
  }
  #yourtel {
    position: relative;
  }
  #yourtel .sendCode {
    position: absolute;
    right: 0;
    top: 1px;
  }
  #leibie .el-select {
    width: 100%;
  }
  .submit .el-button {
    float: none !important;
    width: 50%;
    margin: 0 auto;
    display: block;
  }
}

</style>

 